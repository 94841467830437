import { select, call, put, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import { normalize } from 'normalizr'
import { adminUserListSchema } from '@bit/vidday.vidday-admin-store.middlewares.normalizr'
import requests from './requests'
import { USERS } from './constants'
import { UI } from '../../../../../api/ui/constants'
import configPublic from '../../../../../../config.public'

/**
 * Users Saga worker
 * Fetch users
 * @param {*} action
 */
function* handleFetchUsers(action) {
	yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'info', message: 'Fetching users...' })
	try {
		const response = yield call(requests.fetchUsers, action.payload)
		const normalizedData = normalize(response.data.data, adminUserListSchema)
		const data = { ...response.data, ...normalizedData }
		delete data.data
		yield put({ type: USERS.FETCH_SUCCEEDED, payload: data })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'success', message: data.message })
	} catch (e) {
		yield put({ type: USERS.FETCH_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga Worker
 * Post a user (not necessarily as admin)
 * @param {*} action
 */
function* handlePostUser(action) {
	try {
		const response = yield call(requests.postUser, action.payload)
		const normalizedData = normalize([response.data.data], adminUserListSchema)
		yield put({ type: USERS.POST_SUCCEEDED, payload: normalizedData })
	} catch (e) {
		yield put({ type: USERS.POST_FAILED, payload: e })
	}
}
/**
 * Saga worker
 * Post a user as an admin
 * @param {*} action
 */
function* handlePostUserAsAdmin(action) {
	try {
		const response = yield call(requests.postUserAsAdmin, action.payload)
		const normalizedData = normalize([response.data.data], adminUserListSchema)
		yield put({ type: USERS.POST_AS_ADMIN_SUCCEEDED, payload: normalizedData })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'success', message: data.message })
	} catch (e) {
		yield put({ type: USERS.POST_AS_ADMIN_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Update a user
 * @param {*} action
 */
function* handleUpdateUser(action) {
	try {
		const response = yield call(requests.updateUser, action.payload)
		const normalizedData = normalize([response.data.data], adminUserListSchema)
		console.log(normalizedData, 'normalized data received for updating user')
		yield put({ type: USERS.UPDATE_SUCCEEDED, payload: normalizedData })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: USERS.UPDATE_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Update the role of a user as superadmin
 * @param {*} action
 */
function* handleUpdateUserRole(action) {
	try {
		const response = yield call(requests.updateUserRole, action.payload)
		const normalizedData = normalize([response.data.data], adminUserListSchema)
		console.log(normalizedData, 'normalized data received for updating user')
		yield put({ type: USERS.UPDATE_ROLE_SUCCEEDED, payload: normalizedData })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: USERS.UPDATE_ROLE_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Update a user
 * @param {*} action
 */
function* handleDeleteUser(action) {
	try {
		const response = yield call(requests.deleteUser, action.payload)
		// const normalizedData = normalize(response.data, adminUserListSchema)
		yield put({ type: USERS.DELETE_SUCCEEDED, payload: response.data })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: USERS.DELETE_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Resend a verification email to a user
 */
function* handleResendVerificationEmail(action) {
	try {
		const response = yield call(requests.resendVerificationEmail, action.payload)
		yield put({ type: USERS.RESEND_VERIFICATION_EMAIL_SUCCEEDED, payload: response.data })
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: USERS.RESEND_VERIFICATION_EMAIL_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Saga worker
 * Impersonate a user
 */
function* handleImpersonateUser(action) {
	try {
		const response = yield call(requests.impersonateUser, action.payload)
		yield put({ type: USERS.IMPERSONATE_USER_SUCCEEDED, payload: response.data })
		// yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: response.data.message })
	} catch (e) {
		yield put({ type: USERS.IMPERSONATE_USER_FAILED, payload: e })
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

function* handleImpersonateUserSucceeded(action) {
	try {
		const target = `${configPublic.app.host}/v2/dashboard`
		window.open(target, '_blank')
		yield put({ type: UI.ADD_FLASH_MESSAGE, status: 'success', message: 'now impersonating' })
	} catch (e) {
		yield put({ type: UI.ADD_FLASH_MESSAGE, variant: 'log', status: 'error', message: e.message })
	}
}

/**
 * Users Saga Watcher
 */
export function* watchUsersFetch() {
	// while(true){
	yield takeLatest(USERS.FETCH_REQUESTED, handleFetchUsers)
	// }
}

export function* watchUpdateUser() {
	// while(true){
	yield takeEvery(USERS.UPDATE_REQUESTED, handleUpdateUser)
	// }
}

export function* watchUpdateUserRole() {
	// while(true){
	yield takeEvery(USERS.UPDATE_ROLE_REQUESTED, handleUpdateUserRole)
	// }
}

export function* watchPostUser() {
	// while(true){
	yield takeEvery(USERS.POST_REQUESTED, handlePostUser)
	// }
}

export function* watchPostUserAsAdmin() {
	// while(true){
	yield takeEvery(USERS.POST_AS_ADMIN_REQUESTED, handlePostUserAsAdmin)
	// }
}

export function* watchDeleteUser() {
	// while(true){
	yield takeEvery(USERS.DELETE_REQUESTED, handleDeleteUser)
	// }
}

export function* watchResendVerificationEmail() {
	yield takeEvery(USERS.RESEND_VERIFICATION_EMAIL_REQUESTED, handleResendVerificationEmail)
}

export function* impersonateUser() {
	yield takeEvery(USERS.IMPERSONATE_USER_REQUESTED, handleImpersonateUser)
	yield takeEvery(USERS.IMPERSONATE_USER_SUCCEEDED, handleImpersonateUserSucceeded)
}
