import React, {useState, useEffect, useReducer, useCallback} from 'react' 

/** Import our table component */
import FactoryTable from '../../../../../core/widgets/table/FactoryTableSS'
import {FiltersWrapper} from '../../../../../core/widgets/styles'
import FilterDisplayMode from '../../../../../core/widgets/filters/FilterDisplayMode'
import FilterDropdown from '../../../../../core/widgets/filters/FilterDropdown'
import {useSelector, useDispatch, shallowEqual} from 'react-redux'
import {Container, BottomPanel, RightPanel} from '../../../../../interface'
import {HR} from '../../../../../core/widgets/styles'
import { fetchRegions, postRegion } from '../api/actions' // from '@bit/vidday.vidday-admin-store.modules.users'
import {values} from 'lodash'
import columns from './columns'
import tabs from './tabs'
import {useSearchEngine, serialize, extractKeys} from '../../../../../core'
import axios from 'axios'

import {usa, canada} from '../data'
import { variants } from 'styled-theming'

const getRegions = (s) => {
    var items = s.entities.regions.data || {}
    let collection = []
    collection = values(items)
    return collection
}




const ListView = (props) => {
    
    const dispatch = useDispatch()
    /** Load entities */
    const regions = useSelector(s => getRegions(s))

    /** Use table search engine */
    const searchEngine = useSearchEngine({
        action:fetchRegions,
        slice:'regions',
        actionPrefix:'REGIONS',
    });

    const {state, fetch, search, nextPage, prevPage, setPageSize, setDefaultPageSize, goToPage, setFilter, sortBy } = searchEngine

    // const fetchCountriesFromOrigin = async () => {
    //     let response = axios.get('https://restcountries.eu/rest/v2/all')
        
    //     response.then((response) => {
    //         let data = response.data;
    //         if(data.length > 0){
    //             data.forEach((el, i) => {
    //                 var model = {...el}
    //                 delete model.latlng

    //                 /** Flatten lat and long */
    //                 model.latitude = el.latlng[0]
    //                 model.longitude = el.latlng[1]
    //                 /** Set default currency */
    //                 model.allowedCurrencies = ['USD']

    //                 // console.log('NEW COUNTRY MODEL: ', model)
    //                 //dispatch(postCountry(model))
    //             })
    //         }
    //         console.log("COUNTRIES:", response);
    //     }, (error) => {
    //         console.log("COUNTRIES Errors:", error);
    //     });
    // }


    // useEffect(()=> {
    //     console.log(state, "state location")
    // }, [state])

    // useEffect(() => {
    //     fetchCountriesFromOrigin()
    // }, [])

    const handlePostRegionsCA = () => {
        canada.forEach( (region, i) => {
            var d = {...region, countryCode:'CA'};
            dispatch(postRegion(d));
        })
    }

    const handlePostRegionsUSA = () => {
        usa.forEach( (region, i) => {
            var d = {...region, countryCode:'US'};
            dispatch(postRegion(d));
        })
    }
    
    return (
        <Container>
            <h1>Regions</h1>
            {/* <button onClick={() => handlePostRegionsCA()}>Register Canada</button>
            <button onClick={() => handlePostRegionsUSA()}>Register USA</button> */}
            <HR />
            <FiltersWrapper>
                <div className="left">
                    <FilterDisplayMode current="table" />
                </div>
                <div className="right">
                    <FilterDropdown useCheckbox  current={{value:'publishing', label:'Publishing'}}/>
                </div>
            </FiltersWrapper>
          

            <FactoryTable 
                instance={searchEngine}
                data={regions} 
                tabs={tabs} 
                columns={columns} 
                hiddenColumns={[]} />
           
            <RightPanel>
                <h1>Filters</h1>
            </RightPanel>

            <BottomPanel>
                <h1>More info</h1>
            </BottomPanel>

        </Container>

    )
}

export default ListView;