import React, { useState, useEffect, useReducer, useCallback } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { config } from 'react-transition-group'
import { enginePrefix } from '../reducers/searchEngine'
import { serialize, extractKeys, acceptedKeys } from '../utils/searchEngine'

const defaultConfig = {
	/** Action to dispatch a new GET Request.
	 * this will get passed the params and filters as the payload */
	action: undefined,
	/** Provide the path to the corresponding slice of state (ie. 'lists.users') */
	slice: undefined,
	/** Action prefix to match this corresponding slice (ie. 'USERS') */
	actionPrefix: undefined,
	/** Should we use backend pagination */
	backendPagination: true,
}

const useTableSearchEngine = (config = defaultConfig) => {
	if (!config.action) throw new Error('You need to provide an action used for the fetch.')
	if (!config.slice) throw new Error('You need to provide the path to this slice on the redux store.')
	if (!config.actionPrefix)
		throw new Error(
			'You need to provide a prefix for the actions being dispatch. This should match what you have defined for the slice reducers, for this corresponding slice.'
		)

	/** Manage pagination frontend or backend */
	let keys = acceptedKeys
	if (config.backendPagination == false) {
		keys = ['searchQuery', 'filters', 'sortBy', 'sortByDirection']
	}
	// const _slice = String('@engine.'+config.slice);
	// console.log(_slice, "_slice")
	// // const getDescendantProp = (obj, path) => (
	// //     path.split('.').reduce((acc, part) => acc && acc[part], obj)
	// // );

	/** Retrieve Redux dispatch method, used for fetching */
	const dispatch = useDispatch()
	const selectFromState = (s, sliceName) => {
		return s['@engine'][sliceName]
	}

	const shallow = (props, props2) => {
		console.log(props, props2, 'props shallow', JSON.stringify(props) === JSON.stringify(props2))

		if (JSON.stringify(props) === JSON.stringify(props2)) {
			return true
		} else return false
	}

	/** Retrieve the corresponding state from redux */
	const state = useSelector((s) => selectFromState(s, config.slice), shallowEqual)

	const actionFormat = String(enginePrefix + config.actionPrefix + '/')

	/** Handler to search */
	const search = useCallback((payload) => {
		dispatch({ type: actionFormat + 'search', payload: payload })
	}, [])

	/** Handler to Navigate to next page */
	const nextPage = useCallback(() => {
		dispatch({ type: actionFormat + 'nextPage' })
	}, [])

	/** Handler to Navigate to next page */
	const prevPage = useCallback(() => {
		dispatch({ type: actionFormat + 'prevPage' })
	}, [])

	/** Handler to Navigate to next page */
	const setPageSize = useCallback((payload) => {
		dispatch({ type: actionFormat + 'setPageSize', payload: payload })
	}, [])

	/** Handler to Navigate to next page */
	const setDefaultPageSize = useCallback((payload) => {
		dispatch({ type: actionFormat + 'setDefaultPageSize', payload: payload })
	}, [])

	/** Handler to Navigate to next page */
	const goToPage = useCallback((payload) => {
		dispatch({ type: actionFormat + 'goToPage', payload: payload })
	}, [])

	/** Handler to Navigate to next page */
	const setFilter = useCallback((payload) => {
		dispatch({ type: actionFormat + 'setFilter', payload: payload })
	}, [])

	/** Handler to set sort by and direction */
	const sortBy = useCallback((key, direction) => {
		if (!key || !direction)
			throw new Error(
				'You need to specify a key and a direction in order to use *sortBy*. \n You specified: Current key: ',
				key,
				' Current direction: ',
				direction
			)
		dispatch({ type: actionFormat + 'sortBy', payload: { sortBy: key, sortByDirection: direction } })
	}, [])

	/** This fetch handler can be used to manually trigger a new fetch of the collection, with the updated State  */
	const fetch = useCallback(() => {
		console.log('fetching new collection ?', state)
		/** 1. Serialize data to URI string, after extracting only relevant keys */
		const filteredState = serialize(extractKeys(state, keys))
		console.log('State that will be sent through the fetch action as the payload: ', filteredState)
		/** 2. Dispatch passed fetching action */
		dispatch(config.action(filteredState))
	}, [state])

	/**
	 * Trigger a new search request when state changes
	 */
	useEffect(() => {
		console.log('Search State has been updated : ', state)
		/** 1. Serialize data to URI string, after extracting only relevant keys */
		const filteredState = serialize(extractKeys(state))
		console.log('State that will be sent through the fetch action: ', filteredState)
		/** 2. Trigger a new fetch */
		fetch()
	}, [state])

	/** Return our state and action creators */
	return { state, fetch, search, nextPage, prevPage, setPageSize, setDefaultPageSize, goToPage, setFilter, sortBy }
}

export default useTableSearchEngine
