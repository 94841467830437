import React from 'react'
import { chakra, Divider, SimpleGrid, Button, Flex } from '@chakra-ui/react'
import schemaPromotion from './schema'
import Input from '../../../../../ui/molecules/Input'
import Switch from '../../../../../ui/molecules/Switch'
import Select from '../../../../../ui/molecules/Select'
import Date from '../../../../../ui/molecules/Date'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { typeOptions, isEnabledOptions } from './schema'

const Form = ({ data, onSubmit }) => {
	console.log(data, 'data received through form')
	/** Form Hook */
	const methods = useForm({
		defaultValues: data,
		// mode: 'onBlur',
		resolver: yupResolver(schemaPromotion), //account && account.isRegistered && !account.isActive ? schemaEmail : schema
	})

	const { watch, control, errors, handleSubmit, formState } = methods

	const values = watch()

	return (
		<chakra.form w="full" onSubmit={handleSubmit(onSubmit)}>
			<SimpleGrid columns={[1, 2]} spacing="1rem">
				<Input control={control} errors={errors} name="code" label="Code" />

				<Switch
					errors={errors}
					control={control}
					name="isActive"
					label="Is it active?"
					description="If active, users can redeem the code."
				/>
			</SimpleGrid>

			<Select
				control={control}
				errors={errors}
				name="type"
				placeholder="Type"
				label="Type"
				defaultValue={values.type || ''}
				options={typeOptions.map((el, i) => (
					<option key={i} value={el.value}>
						{el.label}
					</option>
				))}
			/>

			<SimpleGrid columns={[1, 2]} spacing="1rem">
				<Input control={control} errors={errors} name="value" label="Value" />
				<Input control={control} errors={errors} name="maxCount" label="Maximum # use" />
			</SimpleGrid>

			<Date control={control} errors={errors} name="expires" label="Expiry date" useTime allowPast={true} />

			<Input
				control={control}
				errors={errors}
				name="occasion"
				label="Occasion type from Strapi (e.g. 'getwell' for Get Well)"
			/>
			<Input
				control={control}
				errors={errors}
				name="countryCode"
				label="Country alpha2 code (e.g. 'CA' for Canada)"
			/>

			<Flex w="full" my="1rem" justifyContent="center">
				<Button type="submit" variant="solid" minW="150px" isLoading={formState.isSubmitting}>
					Save
				</Button>
			</Flex>
		</chakra.form>
	)
}

export default Form
