import { mode, getColor, transparentize } from '@chakra-ui/theme-tools'

function getBg(props) {
	const { theme, colorScheme: c } = props
	const lightBg = getColor(theme, `${c}.100`, c)
	const darkBg = getColor(theme, `${c}.100`, c) //transparentize(`${c}.200`, 0.16)(theme)
	return mode(lightBg, darkBg)(props)
}

const Alert = {
	baseStyle: (props) => {
		const { colorScheme: c } = props

		return {
			container: {
				// borderColor: 'gray.200',
				// color: 'white',
				// _checked: {
				// 	bg: mode(`${c}.500`, `${c}.500`)(props),
				// 	borderColor: mode(`${c}.500`, `${c}.500`)(props),
				// 	color: mode('white', 'white')(props),
				// 	_hover: {
				// 		bg: mode(`${c}.600`, `${c}.600`)(props),
				// 		borderColor: mode(`${c}.600`, `${c}.600`)(props),
				// 	},
				// 	_disabled: {
				// 		borderColor: mode('gray.200', 'gray.200')(props),
				// 		bg: mode('gray.200', 'gray.200')(props),
				// 		color: mode('gray.500', 'gray.500')(props),
				// 	},
				// },
				// _indeterminate: {
				// 	bg: mode(`${c}.500`, `${c}.500`)(props),
				// 	borderColor: mode(`${c}.500`, `${c}.500`)(props),
				// 	color: mode('white', 'white')(props),
				// },
				// _disabled: {
				// 	bg: mode('gray.100', 'gray.100')(props),
				// 	borderColor: mode('gray.100', 'gray.100')(props),
				// },
				// _focusVisible: {
				// 	boxShadow: 'outline',
				// },
				// _invalid: {
				// 	borderColor: mode('red.500', 'red.500')(props),
				// },
			},
			title: {},
			description: {},
			icon: {},
			spinner: {},
		}
	},
	variants: {
		subtle: (props) => {
			const { colorScheme: c } = props
			return {
				container: { bg: getBg(props) },
				icon: { color: mode(`${c}.500`, `${c}.500`)(props) }, // { color: mode(`${c}.500`, `${c}.200`)(props) },
				spinner: {
					color: mode(`${c}.500`, `${c}.200`)(props),
				},
			}
		},
		solid: (props) => {
			const { colorScheme: c } = props
			return {
				container: {
					bg: mode(`${c}.500`, `${c}.200`)(props),
					color: mode(`white`, `gray.900`)(props),
				},
			}
		},

		// New variant use for toasts
		log: (props) => {
			const { colorScheme: c } = props
			return {
				container: {
					px: '0.25rem',
					py: '0.25rem',
					color: mode(`${c}.500`, `${c}.500`)(props),
					bg: mode(`white`, `gray.900`)(props),
				},
			}
		},
	},
	// variants: {
	// 	outline: (props) => {
	//         return {
	//             field: {
	//                 borderColor: mode("gray.200", "gray.200")(props),
	//                 _hover: {
	//                     borderColor: mode("gray.300", "gray.300")(props),
	//                 }
	//             },
	//             addon: {

	//             }
	//         }
	//     },
	// },
}

export default Alert
