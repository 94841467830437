/** Import our instance config */

import axios from 'axios'
import { pick, reject } from '../../../../core/utils'
import config from '../../../../../config.public'

/** API config instance */
const instance = axios.create({
	withCredentials: true,
	baseURL: `${config.api.baseUrl}/v2`,
})

const rejectedKeys = ['timezone']
// const acceptedKeys = ['productId', 'countryCode', 'currencyCode', 'price', 'taxCodeCategoryId']
// const acceptedKeysPUT = ['countryCode', 'currencyCode', 'price', 'taxCodeCategoryId']
const rejectedKeysPUT = ['code', 'createdAt', 'updatedAt', 'id', 'useCount', 'timezone']

/**
 * Fetch our promotions
 */
const fetchPromotions = async (params) => {
	const response = await instance.get('/promo-codes?' + params)
	return response
}

/**
 * Post a promotion
 * @param {*} data
 * @returns
 */
const postPromotion = async (data) => {
	const filtered = reject(data, rejectedKeys)
	const response = await instance.post('/promo-codes', filtered)
	return response
}

/**
 * Update a promotion
 * @param {*} data
 * @returns
 */
const updatePromotion = async (data) => {
	const filtered = reject(data, rejectedKeysPUT)
	const sanitized = data.map((v) => {
		// swap out blank string "" with null
		if (v == '') {
			return null
		}
		return v
	})
	const response = await instance.put('/promo-codes/' + data.id, sanitized)
	return response
}

/**
 * Delete a promotion
 * @param {*} data
 * @returns
 */
const deletePromotion = async (id) => {
	const response = await instance.delete('/promo-codes/' + id)
	return response
}

const requests = { fetchPromotions, postPromotion, updatePromotion, deletePromotion }

export default requests
