/* eslint-disable no-console */
/* **************************************
 *  LIBRARIES
 * ************************************ */

import React, { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useToast, Box } from '@chakra-ui/react'
import { UI } from '../../../api/ui/constants'

const ToastContainer = () => {
	const toast = useToast()
	const messages = useSelector((s) => s.flashMessages, shallowEqual)
	const dispatch = useDispatch()
	useEffect(() => {
		messages.forEach((el) => {
			if (!toast.isActive(el.id)) {
				// Build base flash message object
				let msg = {
					id: el.id,
					description: el.text,
					status: el.type,
					duration: 9000,
					isClosable: true,
					onCloseComplete: (e) => {
						dispatch({
							type: UI.DELETE_FLASH_MESSAGE,
							id: el.id,
						})
					},
				}

				if (el.variant === 'log') {
					msg = {
						...msg,
						position: 'bottom-left',
						variant: 'log',
						duration: 2500,
						isClosable: false,
						containerStyle: {
							margin: '0 0.5rem 0.5rem',
						},
						// render: () => {
						//     return (
						//         <Box color="green" p={3} bg="white" boxShadow="base" borderRadius="sm">
						//             {el.text}
						//         </Box>
						//     )
						// },
					}
				}

				toast(msg)
			}
		})
	}, [messages])

	return <></>
}

export default ToastContainer
